var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Divider', {
    staticClass: "component-blue",
    attrs: {
      "orientation": "left"
    }
  }, [_vm._v("wangEditor 富文本编辑器")]), _c('Alert', {
    attrs: {
      "type": "info",
      "show-icon": ""
    }
  }, [_vm._v(" 基于 "), _c('a', {
    attrs: {
      "href": "http://www.wangeditor.com",
      "target": "_blank"
    }
  }, [_vm._v("wangEditor v5")]), _vm._v(" 封装，已配置好图片上传(上传至XBoot文件服务或Base64)、视频上传；扩展编辑HTML、附件上传、@用户、暗黑主题等。 ")]), _c('Tabs', {
    model: {
      value: _vm.tabName,
      callback: function ($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, [_c('TabPane', {
    attrs: {
      "label": "素材中心上传",
      "name": "material"
    }
  }), _c('TabPane', {
    attrs: {
      "label": "直接上传",
      "name": "direct"
    }
  }), _c('TabPane', {
    attrs: {
      "label": "附件上传",
      "name": "attach"
    }
  }), _c('TabPane', {
    attrs: {
      "label": "@用户",
      "name": "mention"
    }
  }), _c('TabPane', {
    attrs: {
      "label": "暗黑主题",
      "name": "dark"
    }
  })], 1), _c('wangEditor', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabName == 'material',
      expression: "tabName == 'material'"
    }],
    attrs: {
      "id": "editor-material",
      "material": ""
    },
    model: {
      value: _vm.materialData,
      callback: function ($$v) {
        _vm.materialData = $$v;
      },
      expression: "materialData"
    }
  }), _c('wangEditor', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabName == 'direct',
      expression: "tabName == 'direct'"
    }],
    attrs: {
      "id": "editor-direct"
    },
    model: {
      value: _vm.uploadData,
      callback: function ($$v) {
        _vm.uploadData = $$v;
      },
      expression: "uploadData"
    }
  }), _c('wangEditor', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabName == 'attach',
      expression: "tabName == 'attach'"
    }],
    attrs: {
      "id": "editor-attachment",
      "uploadAttachment": ""
    },
    model: {
      value: _vm.attachData,
      callback: function ($$v) {
        _vm.attachData = $$v;
      },
      expression: "attachData"
    }
  }), _c('wangEditor', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabName == 'mention',
      expression: "tabName == 'mention'"
    }],
    attrs: {
      "id": "editor-mention",
      "mention": ""
    },
    model: {
      value: _vm.mentionData,
      callback: function ($$v) {
        _vm.mentionData = $$v;
      },
      expression: "mentionData"
    }
  }), _c('wangEditor', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tabName == 'dark',
      expression: "tabName == 'dark'"
    }],
    attrs: {
      "id": "editor-dark",
      "dark": ""
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("样式冲突")]), _vm._v(" 在 "), _c('code', [_vm._v("FormItem")]), _vm._v(" 中使用时，建议在该标签上加上 "), _c('code', [_vm._v("class=\"form-noheight\"")]), _vm._v("。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("基础用法")]), _vm._v(" 使用 "), _c('code', [_vm._v("v-model")]), _vm._v(" 实现数据的双向绑定，赋值时外层需包含一个HTML标签（编辑HTML代码时同需注意）。单页面同时使用两个及以上该组件时，需设定不同的id值加以区分。 "), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("props")]), _c('Table', {
    attrs: {
      "columns": _vm.props,
      "data": _vm.data1,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("events")]), _c('Table', {
    attrs: {
      "columns": _vm.events,
      "data": _vm.data2,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  }), _c('h3', {
    staticClass: "component-article"
  }, [_vm._v("methods")]), _c('Table', {
    attrs: {
      "columns": _vm.methods,
      "data": _vm.data3,
      "border": "",
      "size": "small",
      "width": "1000"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };